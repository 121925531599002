import React, { useState } from "react";
import "./Login.css";
import ImageLogo from "../Assets/Assets/AK_society_logo.png";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import axios from 'axios';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


const Login = () => {
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [emailOrPhoneError, setEmailOrPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setEmailOrPhoneError("");
    setPasswordError("");

    if (!validateInputs()) return;

    setLoading(true);
    setDisableButton(true);

    try {
      const response = await axios.post('https://societybackend.helenzysinc.com/api/memberLogin', {
        [isValidEmail(emailOrPhone) ? 'email' : 'phone']: emailOrPhone,
        password,
        roleId: 2
      });

      handleApiResponse(response);
    } catch (error) {
      console.log('Login Error', error);
      setDisableButton(false);
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    let isValid = true;

    if (!emailOrPhone.trim()) {
      setEmailOrPhoneError("Please enter your email or phone number");
      isValid = false;
    } else if (!isValidEmail(emailOrPhone) && !isValidPhone(emailOrPhone)) {
      setEmailOrPhoneError("Please enter a valid email address or phone number");
      isValid = false;
    }

    if (!password.trim()) {
      setPasswordError("Please enter your password");
      isValid = false;
    }

    return isValid;
  };

  const handleApiResponse = (response) => {
    if (response?.data?.success) {
      localStorage.setItem('userId', response?.data?.data.id);
      NotificationManager.success(
        <div className="notification-custom">
          <span className="notification-icon">✔️</span>
          <span className="notification-message">{response.data.message}</span>
        </div>,
        '',
        2000
      );
      setTimeout(() => {
        window.location.href = "/home";
      }, 2000);
    } else {
      NotificationManager.error(
        <div className="notification-custom">
          <span className="notification-icon">❌</span>
          <span className="notification-message">{response.data.message || 'Login failed'}</span>
        </div>,
        '',
        2000
      );
      setTimeout(() => {
        setDisableButton(false);
      }, 2000);
    }
  };


  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidPhone = (phone) => /^\d{10}$/.test(phone);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <img src={ImageLogo} width="25%" height="25%" style={{ margin: '0 auto' }} alt="Logo" />
        <h2>Login</h2>
        <div className="form-group">
          <input
            type="text"
            value={emailOrPhone}
            onChange={(e) => setEmailOrPhone(e.target.value)}
            placeholder="Enter your email or phone number"
            disabled={loading}
          />
          {emailOrPhoneError && <div className="error-message">{emailOrPhoneError}</div>}
        </div>
        <div className="form-group password-input-container">
          <div className="password-input-wrapper">
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              disabled={loading}
            />
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="password-toggle-btn"
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </button>
          </div>
          {passwordError && <div className="error-message">{passwordError}</div>}
        </div>
        <div className="forgot-password">
          <a href="/forgotpassword" style={{ color: "white" }}>
            Forgot password?
          </a>
        </div>
        <div>
        <button type="submit" className="login-btn" disabled={loading || disableButton}>
        {loading ? "Logging in..." : "Login"}
      </button>
        </div>
        {/* <div className="register-link">
          Don't have an Account? <a href="/register">REGISTER HERE</a>
        </div> */}
      </form>
      <NotificationContainer />
    </div>
  );
};

export default Login;